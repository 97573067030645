import React from 'react';
import './Home.css';
import logoimg from '../../Images/logo.png';
import aws from '../../Images/aws.png';
import docker from '../../Images/docker.png';
import ai from '../../Images/ai.png';
import kubernetes from '../../Images/kubernetes.png';
import git from '../../Images/git.png';
import terraform from '../../Images/terraform.png';
import mail from '../../Images/mail.png';  
import pdf from './AWS.pdf';

function Home() {
  return (
    <div className="Home">
      <div className='header'>
        <img className='logoimg' src={logoimg} alt="Skillwave Academy Logo" />
        <h3 className='skillwave'>
          Skillwave Academy
        </h3>
        <i>by Aslaniya Tech</i> 
      </div>
      <div className="comingSoon">
        <h1 style={{ color: "#004aad" }}>COMING SOON</h1>
        <h3>Watch this Space</h3>
      </div>
      <div className="course">
        <h4>Course Offerings</h4>
        <div className='listAll'>
          <div className='git'>
            <img className='gitimg' src={git} alt="git image" />
            <span className='gitName'>Git</span>
          </div>
          <div className='docker'>
            <img className='dockerImg' src={docker} alt="docker image" />
            <span className='dockerName'>Docker</span> 
          </div>
          <div className='aws'>
            <img className='awsImg' src={aws} alt="aws image" />
          </div>
          <div className='ai'>
            <img className='aiImg' src={ai} alt='ai image' />
            <span className='aiName'>Machine Learning / Gen AI</span>
          </div>
          <div className='kubernetes'>
            <img className='kubernetesImg' src={kubernetes} alt='kubernetes image' />
            <span className='kubernetesName'>Kubernetes</span>
          </div>
          <div className='terraform'>
            <img className='terraformImg' src={terraform} alt='terraform image' />
            <span className='terraformName'>Terraform</span>
          </div>
        </div>
      </div>
      <div className='download'>
        <h4>Course Structure</h4>
        <a href={pdf} download="course structure">Download</a>
      </div>
      <div className='footer'>
        <img className='mailImg' src={mail} alt='mail image' />
        <a className="linkName" href="mailto:enquiry@skillwaveacademy.in">enquiry@skillwaveacademy.in</a>
      </div>  
    </div>
  );
}

export default Home;
